const filtersArray = [
  "All",
  "Python",
  "JavaScript",
  "React",
  "Node",
  "Express",
"SQL","R"
,  "PostgreSQL",
  "MongoDB",
"Tensorflow",
"Jupyter Notebook",
  "Text Classification",
  "Fine Tuning",];

export default filtersArray;
